import axios from 'axios';

const setAuthToken = token => {

  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token',token);
    
  } else {
    setTimeout( () => {
    delete axios.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
    },6000)
   
  }

};


export default setAuthToken; 