import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  useContacts, setCurrent
} from '../../context/contact/ContactState';
import s3 from '../awsS3/s3Config';

const ContactItem = ({ contact }) => {
      // we just need the contact dispatch without state.
      const contactDispatch = useContacts()[1];
      
      const { _id, name, email, phone, summary, type, address , imageCover, website } = contact; // imageCover

      const handleDeleteUser = async () => {
          await axios.delete(`/api/auth/${_id}`);
          window.location.reload();
      };

      const [selectedFile, setSelectedFile] = useState(null);
      const [msg, setMsg] = useState(false);
      

      const handleFileChange = async (e) => {
            
             setSelectedFile(e.target.files[0]);
          }; 

          // file name as key
          const fileKey = `uploads/photo-${selectedFile}`
          const oldFileKey = `uploads/photo-${selectedFile}`
          const newFileName = `uploads/${Date.now()}-${selectedFile}`

          const [values, setValues] = useState({ A: newFileName, B: fileKey, C: oldFileKey });

          const swapValues = async() => {
            setValues(({ A, B, C }) => ({ A: oldFileKey, B: newFileName, C: fileKey }));
          };

          // Upload a new object to S3
          const uploadFile = async () => {
            const imageCover = newFileName

             if(!selectedFile){
              setMsg('Select File Below !')
              return
             }
                 setMsg(true); 

                const params = {
                Bucket: "direct-s3-bucket",
                Key: newFileName,
                Body: selectedFile,
                ContentType: selectedFile.type,
                }; 

                try {
                       await s3.upload(params).promise(); // const { Location } =  
                    // setImageSrc(Location)            
                  // Send the image file to the server using fetch or any HTTP client library
                } catch (error) {
                  console.error("Upload failed:", error);
                  setMsg("Upload failed!");
                } finally {
                  setMsg(false);
                }
                  // update the data base
                 await axios.put(`/api/shops/${_id}`,{ imageCover})
                .then((res) => {
                  setMsg('Successful! Refresh The page')
                  setSelectedFile({ file: res.data.file })
                  console.log(res.data)
                  console.log(selectedFile)
                }) 
                window.location.reload()   
                
          }  

          const deleteFile = async () => {
            const params = {
              Bucket: "direct-s3-bucket",
              Key: values.B, // The path to the file in the bucket
            };
          
            try {
              await s3.deleteObject(params).promise();
              console.log("File deleted successfully");
            } catch (error) {
              console.error("Error deleting file:", error);
            }
          };

          
          const handleFileUpdate = async () => {
            await deleteFile(); // Delete the old file
            await swapValues()   //Replace new upload avlue
            await uploadFile(); // Upload the new file
    
          };  
          
  return ( 

    <div className='card bg-light'>
     <h3 className='text-primary text-left'>
        {name}{' '}
        <span
          style={{ float: 'right' }}
          className={
            'badge ' +
            (type === 'eshop' ? 'badge-success' : 'badge-primary')
          }
        >
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </span>
      </h3>
      <ul className='list'>
        {(
            <li>
               <img
                src={`https://direct-s3-bucket.s3.eu-central-1.amazonaws.com/${imageCover}`}
                alt='Contact item cover'
                style={{width: '100px'}} className='round-img'
                 />  
            </li>
          )}
          {email && (
            <li>
              <i className='fas fa-envelope-open' /> {email}
            </li>
          )}
          {phone && (
            <li>
              <i className='fas fa-phone' /> {phone}
            </li>
          )}
          {address && (
            <li>
              <i className='fa-regular fa-address-book' /> {address}
            </li>
          )}

           {website && (
            <li>
              <i className='fa-solid fa-globe' /> {website}
            </li>
          )}
           
          {summary && (
            <li>
              <i className='fas fa-id-card-alt' /> {summary}
            </li>
          )}
      </ul>
      <p>
        <button
          className='btn btn-dark btn-sm'
          onClick={() => setCurrent(contactDispatch, contact)}
        >
          Edit
        </button>
        <button className='btn btn-danger btn-sm' onClick={handleDeleteUser} >
          Delete
        </button>

        <button  className='btn btn-dark btn-sm ' style={{ float: 'right' }}
           onClick={handleFileUpdate}>
           Change Shop Photo
        </button>
        {msg && <span>{msg}</span>}
      </p>
      <div className=''>
       <input
          type="file" onChange={handleFileChange}   
        /> 
        </div>
    </div> 
  );
};

ContactItem.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default ContactItem; 
