import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuth, logout } from '../../context/auth/AuthState';
import { useContacts, clearContacts } from '../../context/contact/ContactState';

    const Navbar = ({ title, icon }) => {
      const [authState, authDispatch] = useAuth();
      const { isAuthenticated, user } = authState;

      // we just need the contact dispatch without state.
      const contactDispatch = useContacts()[1];
        
      const onLogout = () => {
        logout(authDispatch);
        clearContacts(contactDispatch);
      };

      const authLinks = (
        <Fragment>
          <li>{' '}<b>H! {user && user.name}</b></li>
          <li>
            <Link onClick={onLogout} to='/login'>
              <i className='fas fa-sign-out-alt' />{' '}
              <span className='hide-sm'>Logout</span>
            </Link>
          </li>
          
        </Fragment>
      );

      const guestLinks = (
        <Fragment>
          <li>
            <Link to='/register'>Register</Link>
          </li>
          <li>
            <Link to='/login'>Login</Link>
          </li>
          <li>
            <a href='https://www.youtube.com/@chineduiheonu9833'>
              <i className={icon}/>
            </a>
          </li>
          
        </Fragment>
      );

      return (
        <div className='navbar bg-primary'>
            <h1>
              <Link to='/'>
                {title}
              </Link>
            </h1>
            <ul>{isAuthenticated ? authLinks : guestLinks}</ul>
        </div>
      );
};


Navbar.defaultProps = {
  title: 'Welcome To Pickk Shop',
  icon: 'fa-brands fa-youtube'
};

export default Navbar;
